import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "./l.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDollar,
  faBoxOpen,
  faChartBar,
  faStore,
  faCheckCircle,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons';
import "./e.css"
import img from "../../img/undraw_authentication_re_svpt.svg"
import {  toast } from 'react-toastify';
import { apiUrl } from '../../comp/ApiUrl';


  
class Entrar extends Component {
  constructor(props) {
    super(props);
    this.state = {
     email:'',
     senha:'',
     mcnt:false,
     vsenha:false
    };
  }
    componentDidMount(){
              document.title = "Entrar | MaxPdv"
       
    }


   setCookie(name, value, days) {
      let expires = "";
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }


  render() {
    return (
      <>
        <section class="page-lgn">
  <div class="container-fluid h-custom area" >
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-9 col-lg-6 col-xl-5">
        <img src={img} class="img-fluid"
          alt="Sample image"/>
      </div>
      <div class="col-md-8 col-lg-6 col-xl-5 offset-xl-1">
       
       
          <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
       
            
          </div>

          

       
          <div class="form-outline mb-4">
            <label>Usuário ou e-mail:</label>
            <input type="email" id="form3Example3" onChange={(e)=>{
              this.setState({email:e.target.value})
            }} class="form-control form-control-lg"
              placeholder="Digite:" />

          </div>


          <div class="form-outline mb-3"><label>Senha:</label>
          <div className='vsenha'>
            <button onClick={()=>{
              this.setState({vsenha:!this.state.vsenha})
            }}>
            {this.state.vsenha ? <>
              
              <FontAwesomeIcon icon={faEye} />
           
            </> : <>
            
              <FontAwesomeIcon icon={faEyeSlash} />
              
            </>}
            </button>
          </div>
            <input type={this.state.vsenha ? "text" : "password"} id="form3Example4" class="form-control form-control-lg"
            onChange={(e)=>{
              this.setState({senha:e.target.value})
            }}
              placeholder="Digite:" />
          
          </div>

          <div class="d-flex justify-content-between align-items-center">

            <div class="form-check mb-0">
              <input class="form-check-input me-2" onChange={(e)=>{
              this.setState({mcnt:e.target.checked})
            }} type="checkbox" value="" id="form2Example3" />
              <label class="form-check-label" for="form2Example3">
               Manternha-me conectado
              </label>
            </div>
            <Link to="/recuperar-senha" class="text-body">Esqueceu a senha?</Link>
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
    
            <button type="button" class="btn btn-primary btn-lg"
            onClick={(e)=>{
            e.target.classList.add("load")
            e.target.disabled = true;
             


            fetch(`${apiUrl}/oauth/login/`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                e: this.state.email,
                s: this.state.senha,
                m: this.state.mcnt
              }),
            })
              .then(response =>{
                e.target.classList.remove("load")
                e.target.disabled = false;
                
              if(response.status == "404"){
                response.json().then(data=>{
                toast.error(data.errors[0].message)
                })
              }

            
            if(response.status == "403"){
              toast.info("Você excedeu o número permitido de tentativas de login. Por favor, aguarde alguns minutos antes de tentar novamente.")
              return false;
            }
            
              if(response.status == "200"){
                response.json().then(data=>{
              toast.success("Sucesso ao se conectar")
              this.setCookie("acess_token",data.acess_token, 30)
              window.location = '/'
             
                })
              }
              }).catch(error =>{
                e.target.classList.remove("load")
                e.target.disabled = false;
                toast.error("Falha ao enviar!")
              });





            }}
            >Entrar</button>
            <p class="small fw-bold mt-2 pt-1 mb-0">Ainda não tem conta? <Link to="/cadastre-se"
                class="link-danger">Cadastre-se</Link></p>
          </div>

       
      </div>
    </div>
  </div>
  <div class="my-footer d-flex flex-column flex-md-row text-center text-md-start justify-content-between py-4 px-4 px-xl-5" style={{background:"#512b78"}}>

    <div class="text-white mb-3 mb-md-0">
      Copyright © 2024. All rights reserved.
    </div>
   
    <div>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-facebook-f"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-twitter"></i>
      </a>
      <a href="#!" class="text-white me-4">
        <i class="fab fa-google"></i>
      </a>
      <a href="#!" class="text-white">
        <i class="fab fa-linkedin-in"></i>
      </a>
    </div>

  </div>
</section>

      </>
    );
  }
}

export default Entrar;