import React, { Component } from 'react';
import io from 'socket.io-client';

class Jogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      players: {},
      projectiles: [],
      myId: null,
      keysPressed: {},
      health: 100,
    };

    this.lastShot = 0;
    this.lastUpdateTime = 0;

    this.socket = io('https://api.maxpdv.com');

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.gameLoop = this.gameLoop.bind(this);
  }

  componentDidMount() {
    this.socket.on('initialState', (players) => {
      this.setState({ players });
    });

    this.socket.on('newPlayer', (player) => {
      this.setState((prevState) => ({
        players: { ...prevState.players, [player.id]: player.position }
      }));
    });

    this.socket.on('updatePosition', (data) => {
      this.setState((prevState) => ({
        players: { ...prevState.players, [data.id]: data.position }
      }));
    });

    this.socket.on('projectilesUpdate', (projectiles) => {
      this.setState({ projectiles });
    });

    this.socket.on('playerDisconnected', (playerId) => {
      this.setState((prevState) => {
        const players = { ...prevState.players };
        delete players[playerId];
        return { players };
      });
    });

    this.socket.on('updateHealth', (data) => {
      if (data.id === this.state.myId) {
        this.setState({ health: data.health });
      } else {
        this.setState((prevState) => ({
          players: { ...prevState.players, [data.id]: { ...prevState.players[data.id], health: data.health } }
        }));
      }
    });

    this.socket.on('connect', () => {
      this.setState({ myId: this.socket.id });
    });

    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);

    this.gameLoop();
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyDown(event) {
    if (event.code === 'Space' && Date.now() - this.lastShot > 500) {
      this.shootProjectile();
      this.lastShot = Date.now();
    }
    this.setState((prevState) => ({
      keysPressed: { ...prevState.keysPressed, [event.key]: true }
    }));
  }

  handleKeyUp(event) {
    this.setState((prevState) => {
      const keysPressed = { ...prevState.keysPressed };
      delete keysPressed[event.key];
      return { keysPressed };
    });
  }

  shootProjectile() {
    const player = this.state.players[this.state.myId];
    if (player) {
      // Direção do projétil com base na direção do jogador
      const direction = { x: player.vx, y: player.vy };
      const magnitude = Math.sqrt(direction.x ** 2 + direction.y ** 2);
      if (magnitude === 0) return; // Se o jogador não estiver se movendo, não dispara

      // Normalizar a direção
      direction.x /= magnitude;
      direction.y /= magnitude;

      console.log('Shooting projectile with direction:', direction); // Log para depuração
      this.socket.emit('shoot', { x: player.x, y: player.y, direction });
    }
  }

  gameLoop() {
    const now = Date.now();
    const deltaTime = (now - this.lastUpdateTime) / 1000;
    this.lastUpdateTime = now;

    const { keysPressed } = this.state;
    const movement = { x: 0, y: 0 };

    if (keysPressed['ArrowUp']) movement.y = -200 * deltaTime;
    if (keysPressed['ArrowDown']) movement.y = 200 * deltaTime;
    if (keysPressed['ArrowLeft']) movement.x = -200 * deltaTime;
    if (keysPressed['ArrowRight']) movement.x = 200 * deltaTime;

    if (movement.x !== 0 || movement.y !== 0) {
      this.socket.emit('movePlayer', movement);
    }

    // Atualizar posição dos projéteis
    this.setState((prevState) => ({
      projectiles: prevState.projectiles.map(proj => ({
        ...proj,
        x: proj.x + proj.direction.x * 300 * deltaTime, // Ajustar a velocidade do projétil
        y: proj.y + proj.direction.y * 300 * deltaTime
      }))
    }));

    if (this.state.health < 100) {
      this.setState((prevState) => ({
        health: Math.min(prevState.health + 0.1, 100)
      }));
    }

    this.draw();
    requestAnimationFrame(this.gameLoop);
  }

  draw() {
    const canvas = this.refs.gameCanvas;
    const ctx = canvas.getContext('2d');
    const { players, projectiles, myId } = this.state;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    Object.keys(players).forEach((playerId) => {
      const player = players[playerId];
      ctx.fillStyle = playerId === myId ? 'green' : 'red';
      ctx.beginPath();
      ctx.arc(player.x, player.y, 10, 0, 2 * Math.PI);
      ctx.fill();

      // Desenhar a ponta na frente do jogador
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 2;
      const angle = Math.atan2(player.vy, player.vx);
      const x2 = player.x + Math.cos(angle) * 20;
      const y2 = player.y + Math.sin(angle) * 20;
      ctx.beginPath();
      ctx.moveTo(player.x, player.y);
      ctx.lineTo(x2, y2);
      ctx.stroke();

      // Desenhar barra de vida
      if (playerId === myId) {
        ctx.fillStyle = 'white';
        ctx.fillRect(10, 10, 100, 10);
        ctx.fillStyle = 'green';
        ctx.fillRect(10, 10, this.state.health, 10);
      } else {
        // Desenhar a vida dos outros jogadores
        ctx.fillStyle = 'white';
        ctx.fillRect(player.x - 10, player.y - 20, 100, 10);
        ctx.fillStyle = 'green';
        ctx.fillRect(player.x - 10, player.y - 20, player.health, 10);
      }
    });

    projectiles.forEach((proj) => {
      ctx.fillStyle = 'yellow';
      ctx.beginPath();
      ctx.arc(proj.x, proj.y, 5, 0, 2 * Math.PI);
      ctx.fill();
    });
  }

  render() {
    return (
      <div>
        <canvas ref="gameCanvas" width={800} height={600} style={{ border: '1px solid black' }}></canvas>
      </div>
    );
  }
}

export default Jogo;
