import React, { useState, useEffect } from 'react';
import { Route, Routes, Router, useNavigate} from "react-router-dom"
import Inicio from "./paginas/Inicio";
import Header from "./componets/Header";
import Page404 from "./paginas/Page404";
import Funcoes from "./paginas/Funcoes";
import Entrar from "./paginas/Entrar";
import Cadastro from "./paginas/Cadastro";
import RecuperSenha from "./paginas/RecuperSenha";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './paginas/Dashboard';
import HeaderDah from './paginas/Dashboard/HeaderDah';
import D404 from './paginas/Dashboard/404';
import Aside from './paginas/Dashboard/Aside';
import Jogo from './paginas/Jogo';
function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigateTo = useNavigate();
  const [isLoad, setIsload] = useState(true);
  useEffect(() => {
    const authToken = document.cookie.includes('acess_token');
    setIsload(false)
    setIsAuthenticated(authToken);
  }, []);


if(isLoad){
  return(<div className='loader'>
  <div class="spinner"></div>
  </div>)
}


if(isAuthenticated){
return(<>
    <HeaderDah/>
    <ToastContainer />
    <main class="sc-115bd4ad-0 kZqBWo">
    <Aside/>
    <Routes>
    <Route exact  path="/"  element={<Dashboard  navigate={navigateTo}/>} />
    <Route exact  path="*"  element={<D404 navigate={navigateTo}/>} />
    </Routes>
    </main>
</>)
}else{
  return (
    <>
   
    <ToastContainer />
    <Routes>
    
   
    <Route exact  path="/funcoes"  element={<Funcoes/>} />
    <Route exact  path="/"  element={<Entrar navigate={navigateTo}/>} />
    <Route exact  path="/jogo"  element={<Jogo navigate={navigateTo}/>} />
    <Route exact  path="/recuperar-senha"  element={<RecuperSenha/>} />
    <Route exact  path="/cadastre-se"  element={<Cadastro/>} />
    <Route exact  path="*"  element={<Page404 navigate={navigateTo}/>} />

    </Routes>
  
    </>
  );
}
  
}

export default App;
